import React from "react"

import Layout from "../components/Layout"
import AboutPage from "../views/AboutPage"

const About = ({ location, pageContext: { locale } }) => (
    <Layout location={location} locale={locale}>
        <AboutPage />
    </Layout>
);

export default About;
